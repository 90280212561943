.contact{
    margin-top: 70px;
    p{
        a{
            transition: filter 1s;
            &:hover{
                filter: drop-shadow(0 10px 10px #000);
            }
        }
    }
}
@font-face{
    font-family: hpsimplified;
    src: url(../../assets/font/hpsimplified.ttf);
}
.header{
    margin-top: 60px;
    width: 650px;
    display: flex;
    flex-direction: row;
    position: relative;
    z-index: 3;
    .logo{
        margin-top: 15px;
    }
    ul{
        
        text-decoration: none;
        list-style: none;
        display: flex;
        border-bottom: 1px solid black;
        li{
            margin-right: 26px;
            font-size: 20px;
            font-family: hpsimplified;
            a{
                text-decoration: none;
                color: black;
            }
        }
    }
}
@media only screen and (max-width: 1024px){
    .header{
        width: 40%;
        ul{
            margin-left: -5px;
            li{
                margin-right: 10px;
                font-size: 16px;
            }
        }
    }
}
@media only screen and (max-width: 899px){
    .header{
        width: 40%;
        margin-top: 0;
        margin-right: auto;

        .logo{
            img{
                width: 50px;
                height: 50px;
            }
        }
        ul{
            margin-left: 0px;
            background-color: white;
            li{
                margin-right: 10px;
                font-size: 16px;
            }
        }
    }
}
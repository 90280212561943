@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&family=Roboto&display=swap');
body{
    box-sizing: border-box;
    background: rgb(190, 190, 190, 0.2);
}
.canvas{
    width: 75%;
    margin: 0 auto;
}
strong{
    font-family: 700;
}
html{
    scroll-behavior: smooth;
}
*{
    box-sizing: border-box;
}
@media only screen and (max-width: 1440px){
    .canvas{
        width: 85%;
    }
}
@media only screen and (max-width: 1024px){
    
}
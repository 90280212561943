.project{
    width: 100%;
    position: relative;
    z-index: 1;
    &-item{
        margin-top: 100px;
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .col{
        width: 50%;
        .project-pic{
            box-shadow:
            0 2.8px 2.2px rgba(0, 0, 0, 0.034),
            0 6.7px 5.3px rgba(0, 0, 0, 0.048),
            0 12.5px 10px rgba(0, 0, 0, 0.06),
            0 22.3px 17.9px rgba(0, 0, 0, 0.072),
            0 41.8px 33.4px rgba(0, 0, 0, 0.086),
            0 100px 80px rgba(0, 0, 0, 0.12)
          ;
            transition: transform 2s;
            &:hover{
                transform: scale(1.1);
            }
        }
        img{
            width: 400px;
            height: 300px;
        }
    }
}
@media only screen and (max-width: 1024px){
    .project{
        &-item{
            flex-direction: column;
        }
        .col{
            width: 70%;
            margin: 0 auto;
        }
    }
}
@media only screen and (max-width: 899px){
   .project{ .col{
        img{
            width: 220px;
            height: 220px;
        }
    }
}
}
.a{
    background-color: white;
    box-shadow:
    0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px rgba(0, 0, 0, 0.048),
    0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 22.3px 17.9px rgba(0, 0, 0, 0.072),
    0 41.8px 33.4px rgba(0, 0, 0, 0.086),
    0 100px 80px rgba(0, 0, 0, 0.12)
  ;
}
.about{
   
    z-index: 4;
    position: relative;
    &::before{
        display: block;
        content: '';
        background-image: url(../../assets/pt.svg);
        width: 200px;
        height: 200px;
        margin-left: -1px;

    }
    h1{
        margin-top: -100px;
    }
    .row{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        div{
            width: 50%;
            p{
                width: 70%;
                margin: 100px auto;
                line-height: 2;
                text-align: justify;
                font-size: 19px;
            }
            img{
                margin-top: 100px;
            }
        }
    }
}

.more{
    padding-bottom: 2%;
    padding-top: 2%;
    .hobbies{

        width: 50%;
        margin: 0 auto 40px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        position: relative;
    }
}
@media only screen and (max-width: 1440px){
    .about{
        .row{
            div{
                p{
                    width: 85%;
                    line-height: 1.5;
                }
            }
        }
    }
    .more{
    .hobbies{
        margin: 10px auto 40px auto;
        width: 60%;
    }
}
}
@media only screen and (max-width: 1336px){
    .about{
        .row{
            div{
                P{
                    margin: 50px auto;
                }
                img{
                    margin: 50px auto;
                }
            }
        }
    }
}
@media only screen and (max-width: 1024px){
    .about{
        .row{
            flex-direction: column;
            div{
                width: 100%;
                margin: 0 auto;
                img{
                    width: 300px;
                    height: 300px;
                }
            }
        }
    }
    .more{
        .hobbies{

            width: 80%;
            margin: 0 auto 40px;
        }
        &-p{
            width: 70%;
            margin: 0 auto 40px;
        }
    }
}
@media only screen and (max-width: 899px){
    .about{
        h1{
            margin-top: 0;
        }
    }
    .more{
        .hobbies{
            flex-direction: column;
        }
        &-p{
            width: 90%;
            p{
                img{
                    display: none;
                }
            }
        }
    }
}

.section{
    text-align: center;
    font-family: Roboto, sans-serif;
    h1{
        font-size: 46px;
        font-weight: 400;
    }
}
@media only screen and (max-width: 899px){
    .section{
        h1{
            font-size: 42px;
        }
    }
}
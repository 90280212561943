.intro{
    margin-top: 70px;
    h1{
        font-size: 56px;
        font-family: Montserrat, sans-serif;
        font-weight: 400;
    }
}
@media only screen and (max-width: 899px){
    .intro{
        h1{
            font-size: 30px;
        }
    }
}
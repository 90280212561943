@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&family=Roboto&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body{
    box-sizing: border-box;
    background: rgb(190, 190, 190, 0.2);
}
.canvas{
    width: 75%;
    margin: 0 auto;
}
strong{
    font-family: 700;
}
html{
    scroll-behavior: smooth;
}
*{
    box-sizing: border-box;
}
@media only screen and (max-width: 1440px){
    .canvas{
        width: 85%;
    }
}
@media only screen and (max-width: 1024px){
    
}
.top{height:100vh}@media only screen and (width: 1024px) and (height: 1366px){.top{height:70vh}}@media only screen and (width: 768px) and (height: 1024px){.top{height:70vh}}@media only screen and (max-width: 899px){.top{height:85vh}}

@font-face{font-family:hpsimplified;src:url(/static/media/hpsimplified.8d37c1fa.ttf)}.header{margin-top:60px;width:650px;display:flex;flex-direction:row;position:relative;z-index:3}.header .logo{margin-top:15px}.header ul{text-decoration:none;list-style:none;display:flex;border-bottom:1px solid black}.header ul li{margin-right:26px;font-size:20px;font-family:hpsimplified}.header ul li a{text-decoration:none;color:black}@media only screen and (max-width: 1024px){.header{width:40%}.header ul{margin-left:-5px}.header ul li{margin-right:10px;font-size:16px}}@media only screen and (max-width: 899px){.header{width:40%;margin-top:0;margin-right:auto}.header .logo img{width:50px;height:50px}.header ul{margin-left:0px;background-color:white}.header ul li{margin-right:10px;font-size:16px}}

.intro{margin-top:70px}.intro h1{font-size:56px;font-family:Montserrat, sans-serif;font-weight:400}@media only screen and (max-width: 899px){.intro h1{font-size:30px}}

.dp{width:250px;height:250px;position:absolute;left:calc(50% - 125px);top:calc(50% - 125px);border-radius:50%;-webkit-filter:drop-shadow(10px 10px 10px grey);filter:drop-shadow(10px 10px 10px grey);z-index:10}.dev{position:absolute;top:-10%;left:calc(50% + 100px);z-index:1;-webkit-animation:slideintop 1.5s ease-in-out;animation:slideintop 1.5s ease-in-out}@-webkit-keyframes slideintop{from{transform:translateY(-1300px)}to{transform:translateY(0)}}@keyframes slideintop{from{transform:translateY(-1300px)}to{transform:translateY(0)}}@-webkit-keyframes slideinbottom{from{transform:translateY(5300px)}to{transform:translateY(0)}}@keyframes slideinbottom{from{transform:translateY(5300px)}to{transform:translateY(0)}}.web{position:absolute;top:500px;left:calc(50% - 250px);z-index:1;-webkit-animation:slideinbottom 1s ease-in-out;animation:slideinbottom 1s ease-in-out}@media only screen and (width: 768px) and (height: 1024px){.dev{width:auto;width:initial;top:-10%;left:calc(50% + 100px)}.web{width:auto;width:initial;top:500px;left:calc(50% - 250px)}.dp{width:inherit;height:auto;height:initial;top:-10%;left:calc(50% + 100px)}}@media only screen and (max-width: 899px){.dev{width:150px;left:calc(50% + 50px)}.web{top:400px;width:150px;left:calc(50% - 150px)}.dp{width:200px;height:200px;left:25%;top:calc(50% - 125px)}}

.section{text-align:center;font-family:Roboto, sans-serif}.section h1{font-size:46px;font-weight:400}@media only screen and (max-width: 899px){.section h1{font-size:42px}}

.a{background-color:white;box-shadow:0 2.8px 2.2px rgba(0,0,0,0.034),0 6.7px 5.3px rgba(0,0,0,0.048),0 12.5px 10px rgba(0,0,0,0.06),0 22.3px 17.9px rgba(0,0,0,0.072),0 41.8px 33.4px rgba(0,0,0,0.086),0 100px 80px rgba(0,0,0,0.12)}.about{z-index:4;position:relative}.about::before{display:block;content:'';background-image:url(/static/media/pt.f4bffe81.svg);width:200px;height:200px;margin-left:-1px}.about h1{margin-top:-100px}.about .row{display:flex;flex-direction:row;flex-wrap:wrap}.about .row div{width:50%}.about .row div p{width:70%;margin:100px auto;line-height:2;text-align:justify;font-size:19px}.about .row div img{margin-top:100px}.more{padding-bottom:2%;padding-top:2%}.more .hobbies{width:50%;margin:0 auto 40px;display:flex;flex-direction:row;justify-content:space-between;position:relative}@media only screen and (max-width: 1440px){.about .row div p{width:85%;line-height:1.5}.more .hobbies{margin:10px auto 40px auto;width:60%}}@media only screen and (max-width: 1336px){.about .row div P{margin:50px auto}.about .row div img{margin:50px auto}}@media only screen and (max-width: 1024px){.about .row{flex-direction:column}.about .row div{width:100%;margin:0 auto}.about .row div img{width:300px;height:300px}.more .hobbies{width:80%;margin:0 auto 40px}.more-p{width:70%;margin:0 auto 40px}}@media only screen and (max-width: 899px){.about h1{margin-top:0}.more .hobbies{flex-direction:column}.more-p{width:90%}.more-p p img{display:none}}

.box-container{margin-top:40px;position:relative}.box-container:first-child{margin-left:0}.box{cursor:pointer;display:flex;flex-direction:column;justify-content:center;align-items:center;border:2px solid black;width:250px;height:320px;box-shadow:6px 10px 10px rgba(0,0,0,0.2);border-radius:0px;transition:border-radius 1s}.box:hover{border-radius:50px;box-shadow:inset 0 10px 10px rgba(0,0,0,0.2)}.box .skill-img{width:90px;height:90px;margin-bottom:40px}.accomplish{width:230px;height:250px;padding:0 10px;top:250px;left:10px;opacity:1;position:absolute;z-index:4;background-color:white;border-radius:20px;box-shadow:6px 10px 10px rgba(0,0,0,0.2);text-align:center;overflow:auto}.accomplish h4{font-weight:900;font-size:18px;text-decoration:underline;text-transform:uppercase}.hobby-box{display:flex;cursor:pointer;flex-direction:column;justify-content:center;align-items:center;border:2px solid black;width:150px;height:180px;transition:border-radius 1s}.hobby-box:hover{border-radius:30px;box-shadow:inset 0 10px 10px rgba(0,0,0,0.2)}.about-hobby{position:absolute;background-color:white;top:-30px;left:-90px;width:200px;height:100px;border:2px solid black;transition:border-radius 1s;box-shadow:6px 10px 10px rgba(0,0,0,0.2)}.about-hobby:hover{border-radius:0 0 30px 0}.about-hobby h3{margin-bottom:10px}@media only screen and (max-width: 1440px){.box{width:170px;height:220px}.box .skill-img{width:60px;height:60px;margin-bottom:20px}.box h3{padding:0 20px}.accomplish{width:170px;height:200px;top:190px;padding:0 10px;left:5px}.accomplish h4{font-weight:900;font-size:14px;text-decoration:underline;text-transform:uppercase}}@media only screen and (max-width: 899px){.box-container{margin:20px auto}.box-container:first-child{margin:auto}}

.skills{margin-top:100px;margin-bottom:200px;position:relative}.skills p{font-style:italic}.skills .skill-list{width:100%;display:flex;flex-direction:row;flex-wrap:wrap;margin:70px auto;justify-content:space-between}

.project{width:100%;position:relative;z-index:1}.project-item{margin-top:100px;display:flex;flex-direction:row;align-items:center}.project .col{width:50%}.project .col .project-pic{box-shadow:0 2.8px 2.2px rgba(0,0,0,0.034),0 6.7px 5.3px rgba(0,0,0,0.048),0 12.5px 10px rgba(0,0,0,0.06),0 22.3px 17.9px rgba(0,0,0,0.072),0 41.8px 33.4px rgba(0,0,0,0.086),0 100px 80px rgba(0,0,0,0.12);transition:transform 2s}.project .col .project-pic:hover{transform:scale(1.1)}.project .col img{width:400px;height:300px}@media only screen and (max-width: 1024px){.project-item{flex-direction:column}.project .col{width:70%;margin:0 auto}}@media only screen and (max-width: 899px){.project .col img{width:220px;height:220px}}

.contact{margin-top:70px}.contact p a{transition:-webkit-filter 1s;transition:filter 1s;transition:filter 1s, -webkit-filter 1s}.contact p a:hover{-webkit-filter:drop-shadow(0 10px 10px #000);filter:drop-shadow(0 10px 10px #000)}

.social-block{display:flex;justify-content:space-between;width:40%;margin:50px auto;align-items:center}.social-item{-webkit-filter:drop-shadow(0 10px 10px #000);filter:drop-shadow(0 10px 10px #000);position:relative;transition:-webkit-filter 1s;transition:filter 1s;transition:filter 1s, -webkit-filter 1s}.social-item .social-about{position:absolute;top:-35px;color:white;background-color:black;padding:5px 10px;left:10px}.social-item:hover{-webkit-filter:drop-shadow(0 20px 20px #000);filter:drop-shadow(0 20px 20px #000)}@media only screen and (max-width: 1024px){.social-block{width:70%}}@media only screen and (max-width: 899px){.social-block{width:85%}}


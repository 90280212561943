.box-container{
    &:first-child{
        margin-left: 0;
    }
    margin-top: 40px;
    position: relative;
}
.box{
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 2px solid black;
    width: 250px;
    height: 320px;
    box-shadow: 6px 10px 10px rgba($color: #000000, $alpha: 0.2);
    border-radius: 0px;
    transition: border-radius 1s;
    &:hover{
        border-radius: 50px;
        box-shadow: inset  0 10px 10px rgba($color: #000000, $alpha: 0.2);
    }
    .skill-img{
        width: 90px;
        height: 90px;
        margin-bottom: 40px;
    }
}
.accomplish{
    width: 230px;
    height: 250px;
    // margin-top: -50px;
    // margin-left: 30px;
    padding: 0 10px;
    top: 250px;
    left: 10px;
    opacity: 1;
    position: absolute;
    z-index: 4;
    background-color: white;
    border-radius: 20px;
    box-shadow: 6px 10px 10px rgba($color: #000000, $alpha: 0.2);
    text-align: center;
    overflow: auto;
    h4{
        font-weight: 900;
        font-size: 18px;
        text-decoration: underline;
        text-transform: uppercase;
    }
}
.hobby-box{
    display: flex;
    cursor: pointer;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 2px solid black;
    width: 150px;
    height: 180px;
    transition: border-radius 1s;
    &:hover{
        border-radius: 30px;
        box-shadow: inset  0 10px 10px rgba($color: #000000, $alpha: 0.2);
    }
}
.about-hobby{
    position: absolute;
    background-color: white;
    top: -30px;
    left: -90px;
    width: 200px;
    height: 100px;
    border: 2px solid black;
    transition: border-radius 1s;
    box-shadow: 6px 10px 10px rgba($color: #000000, $alpha: 0.2);
    &:hover{
        border-radius: 0 0 30px 0;
        // box-shadow: inset  0 10px 10px rgba($color: #000000, $alpha: 0.2);
    }
    h3{
        margin-bottom: 10px;
    }
}

@media only screen and (max-width: 1440px){
    .box{
        width: 170px;
        height: 220px;
        .skill-img{
            width: 60px;
            height: 60px;
            margin-bottom: 20px;
        }
        h3{
            padding: 0 20px;
        }
    }
    .accomplish{
        width: 170px;
        height: 200px;   
        top: 190px;
        padding: 0 10px;
    left: 5px;
    h4{
        font-weight: 900;
        font-size: 14px;
        text-decoration: underline;
        text-transform: uppercase;
    }
    }
}
@media only screen and (max-width: 899px){
    .box-container{
        margin: 20px auto;
        &:first-child{
            margin:  auto;
        }
    }
}